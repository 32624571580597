import { useEffect } from 'react';
import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import { performLogout, selectAuth, setInitialized, setLoadingToken, setLoadingUser, setToken, setUser } from '../store/slices/auth';
import { useSelector } from 'react-redux';
import { IUser } from '../store/models/IUser';
import { addErrorAlert } from '../store/slices/alert';
import { useAppDispatch } from '../store/store';
import { TOKEN_COOKIE_NAME, USER_COOKIE_NAME, cookies, setCookieNeverExpired } from './cookies';

let initOptions = {
  url: process.env.REACT_APP_KEYCLOAK_HOST!,
  realm: process.env.REACT_APP_KEYCLOAK_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT!,
  onLoad: 'check-sso', // check-sso | login-required
  KeycloakResponseType: 'code'
}

export const kc = new Keycloak(initOptions);

export const kcInitOptions = {
    onLoad: initOptions.onLoad,
    KeycloakResponseType: 'code',
    //silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html", 
    silentCheckSsoFallback: false,
    checkLoginIframe: false,
    pkceMethod: 'S256',
    scope: "openid profile email"
} as KeycloakInitOptions

export const useInitAuth = () => {
    const auth = useSelector(selectAuth)
    const dispatch = useAppDispatch()

    useEffect(() => {
        //@ts-ignore
        if(!kc.didInitialize) {
            dispatch(setInitialized(true))
            dispatch(setLoadingToken(true))
            kc.onTokenExpired = () => {
                kc.updateToken(5)
                .then(() => {
                    dispatch(setToken({token: kc.token!}))
                    setCookieNeverExpired(TOKEN_COOKIE_NAME, kc.refreshToken!);
                }).catch(error => {
                    console.error(error)
                    dispatch(addErrorAlert("Impossible rigenerare la sessione scaduta", error));
                    dispatch(performLogout())
                })
            }
            const refreshToken = cookies.get(TOKEN_COOKIE_NAME);
            kc.init({...kcInitOptions, refreshToken})
            .then(authenticated => {
                if(authenticated) {
                    setCookieNeverExpired(TOKEN_COOKIE_NAME, kc.refreshToken!);
                    dispatch(setToken({token: kc.token!}))
                    dispatch(setLoadingUser(true))
                    kc.loadUserInfo()
                        .then((userInfo) => {
                            dispatch(setUser(userInfo as IUser))
                            setCookieNeverExpired(USER_COOKIE_NAME, JSON.stringify(userInfo));
                        })
                        .catch(error => {
                            console.error(error)
                            dispatch(addErrorAlert("Impossible recuperare le informazioni utente", error));
                        })
                        .finally(() => {
                            dispatch(setLoadingUser(false))
                        })
                } else {
                    dispatch(setInitialized(false))
                    dispatch(setLoadingToken(false))
                    dispatch(performLogout())
                }
            })
            .catch(error => {
                console.error(error)
                dispatch(addErrorAlert("Impossibile recuperare la sessione utente", error));
            })
            .finally(() => {
                dispatch(setInitialized(false))
                dispatch(setLoadingToken(false))
            })
        }
    }, [])

    return {
        auth,
        keycloak: kc
    }
}

interface IUseAuth {
    initialize?: boolean
}

export const useAuth = () => {
    const auth = useSelector(selectAuth)
    return {
        auth,
        isAuthenticated: !auth.loadingToken && !auth.loadingUser && !auth.loadingInit && kc.authenticated,
        keycloak: kc
    }
}