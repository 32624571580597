import React, {FunctionComponent} from 'react';
import { ICategory } from '../../store/models/ICategories';
import { TreeView, TreeViewDataItem } from '@patternfly/react-core';

interface ICategories{
    categories: ICategory[];
    setSelectedItem: React.Dispatch<React.SetStateAction<ICategory[]>>;
    selectedItem: ICategory[];
}
const Categories: FunctionComponent<ICategories> = ({categories=[], selectedItem=[], setSelectedItem}) => {
    // const options: TreeViewDataItem[] = categories;

    const flattenTree = (tree: ICategory[]) => {
        let result: ICategory[] = [];
        tree.forEach((item) => {
          result.push(item);
          if (item.children) {
            result = result.concat(flattenTree(item.children));
          }
        });
        return result;
      };

    const filterItems: Function = (item: ICategory, checkedItem: ICategory) => {
        if (item.id === checkedItem.id) {
          return true;
        }
  
        if (item.children) {
          return (
            (item.children = item.children
              .map((opt) => Object.assign({}, opt))
              .filter((child: ICategory) => filterItems(child, checkedItem))).length > 0
          );
        }
    };

    const onSelect = (_event: React.ChangeEvent<HTMLInputElement>, treeViewItem: TreeViewDataItem) => {
        const checked = _event.target.checked;

        const checkedItemTree = categories
            .map((opt) => Object.assign({}, opt))
            .filter((item) => filterItems(item, treeViewItem));
        const flatCheckedItems = flattenTree(checkedItemTree);
        // console.log('flat', flatCheckedItems);
        
        setSelectedItem(
            (oldSelectedItem) => {
                return checked
                ? oldSelectedItem.concat(
                    flatCheckedItems.filter((item) => !oldSelectedItem.some((i) => i.id === item.id))
                    )
                : oldSelectedItem.filter((item) => !flatCheckedItems.some((i) => i.id === item.id))
            }
        );
    };

    const mapTree: any = (item: ICategory) => {
        const hasCheck = areAllDescendantsChecked(item);
        // Reset checked properties to be updated
        item.checkProps.checked = false;
  
        if (hasCheck) {
          item.checkProps.checked = true;
        } else {
          const hasPartialCheck = areSomeDescendantsChecked(item);
          if (hasPartialCheck) {
            item.checkProps.checked = null;
          }
        }
  
        if (item.children) {
          return {
            ...item,
            children: item.children.map((child) => mapTree(child))
          };
        }
        return item;
      };

      const isChecked = (dataItem: ICategory) => selectedItem.some((item: ICategory) => item.id === dataItem.id);
      const areAllDescendantsChecked = (dataItem: ICategory): boolean =>
          dataItem.children ? dataItem.children.every((child) => areAllDescendantsChecked(child)) : isChecked(dataItem);
      const areSomeDescendantsChecked = (dataItem: ICategory): boolean =>
          dataItem.children ? dataItem.children.some((child) => areSomeDescendantsChecked(child)) : isChecked(dataItem);

    
    const mapped = categories.map((category: ICategory) => mapTree(category));
    return (
        <TreeView data={mapped} hasGuides={true}  onCheck={onSelect} hasCheckboxes defaultAllExpanded={true} />
    );
}

export {Categories};