import * as React from 'react';
import { ExclamationTriangleIcon } from '@patternfly/react-icons';
import {
  PageSection,
  Title,
  Button,
  EmptyState,
  EmptyStateIcon,
  EmptyStateBody,
  EmptyStateVariant,
} from '@patternfly/react-core';
import { useNavigate } from 'react-router-dom';
import { DashboardLayout } from "../../components/Layout/DashboardLayout";

const NotFound: React.FunctionComponent = () => {

  function GoHomeBtn() {
    const navigate = useNavigate();
    function handleClick() {
        navigate('/');
    }
    return (
      <Button onClick={handleClick}>Take me home</Button>
    );
  }

  return (
    <DashboardLayout>
      <PageSection>
          <EmptyState variant={EmptyStateVariant.full}>
              <EmptyStateIcon icon={ExclamationTriangleIcon} />
              <Title headingLevel="h1" size="lg">
                  404 Page not found
              </Title>
              <EmptyStateBody>
                  We didn&apos;t find a page that matches the address you navigated to.
              </EmptyStateBody>
              <GoHomeBtn />
          </EmptyState>
      </PageSection>
    </DashboardLayout>
  )
};

export { NotFound };