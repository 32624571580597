import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { PageSection, PageSectionVariants, TextContent, Text, TextVariants} from '@patternfly/react-core';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { DashboardLayout } from "../../components/Layout/DashboardLayout";
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import { selectToken } from '../../store/slices/auth';
import { useQuery } from '@tanstack/react-query';
import { IPage } from '../../store/models/IPage';
import { ListRes, getPage } from '../../store/api';


const Page: React.FunctionComponent = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch();
  const token = useSelector(selectToken)


  const {data: page, error: pageError} = useQuery<IPage>({
    queryKey: ['page', id], 
    queryFn: async () =>  await getPage(id!, token!),
  });

  return (
    <DashboardLayout
    hasBreadcrumb={false}
    isSticky={false}
    pageHeader={
      <PageSection variant={PageSectionVariants.light}>
            <TextContent>
              <Text component="h1">{page?.title}</Text>
            </TextContent>
      </PageSection>
      }
    >
      <PageSection variant={PageSectionVariants.light}>
      {page?.content && <TextContent>
        <BlocksRenderer 
          content={page?.content} 
          blocks={{
            // You can use the default components to set class names...
            paragraph: ({ children }) => <Text component={TextVariants.p}>{children}</Text>,
            // ...or point to a design system
            heading: ({ children, level }) => {
              switch (level) {
                case 1:
                  return <Text component={TextVariants.h1}>{children}</Text>
                case 2:
                  return <Text component={TextVariants.h2}>{children}</Text>
                case 3:
                  return <Text component={TextVariants.h3}>{children}</Text>
                case 4:
                  return <Text component={TextVariants.h4}>{children}</Text>
                case 5:
                  return <Text component={TextVariants.h5}>{children}</Text>
                case 6:
                  return <Text component={TextVariants.h6}>{children}</Text>
                default:
                  return <Text component={TextVariants.h1}>{children}</Text>
              }
            },
            // For links, you may want to use the component from your router or framework
            link: ({ children, url }) => <Link to={url} >{children}</Link>,
          }}
          modifiers={{
            bold: ({ children }) => <strong>{children}</strong>,
            italic: ({ children }) => <span className="italic">{children}</span>,
          }}
          />
        </TextContent>}
      

      </PageSection>
    </DashboardLayout>
  )
}

export { Page };