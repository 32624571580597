import * as React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Nav, NavList, NavItem, NavExpandable, PageSidebar, PageSidebarBody, PageSectionVariants } from '@patternfly/react-core';
import { NavigationItem, navigations } from '../../Routes';
import { IMenu } from '../../store/models/IMenu';

interface IDashboardSidebar {
  isNavOpen: boolean;
  menu: IMenu[]
}

const DashboardSidebar: React.FunctionComponent<IDashboardSidebar> = ({isNavOpen, menu}) => {
  const navigate = useNavigate();
  const location = useLocation();


  const renderNavItem = (route: NavigationItem, index: number) => (
    <NavItem key={`${route.label}-${index}`} id={`${route.label}-${index}`} isActive={route.path === location.pathname}>
      <NavLink to={route.path}>
        {route.label}
      </NavLink>
    </NavItem>
  );

  const renderNavGroup = (group: NavigationItem, groupIndex: number) => {
    const isActive = !!group.children && group.children.some((route) =>  location.pathname.includes(route.path))
    return <NavExpandable
      key={`${group.label}-${groupIndex}`}
      id={`${group.label}-${groupIndex}`}
      title={group.label}
      buttonProps={{onClick: () => navigate(group.path)}}
      isActive={isActive}
      isExpanded={isActive}
    >
      {group.children && group.children.map((route, idx) => route.label && renderNavItem(route, idx))}
    </NavExpandable>
  };

  const renderMenuItem = (route: IMenu, index: number) => (
    <NavItem key={`${route.name}-${index}`} id={`${route.name}-${index}`} isActive={route.path === location.pathname}>
      <NavLink to={route.path}>
        {route.name}
      </NavLink>
    </NavItem>
  );

  const renderMenuGroup = (group: IMenu, groupIndex: number) => {
    const isActive = !!group.menus && (location.pathname.includes(group.path) || group.menus.some((route) =>  location.pathname.includes(route.path)))
    return <NavExpandable
      key={`${group.name}-${groupIndex}`}
      id={`${group.name}-${groupIndex}`}
      title={group.name}
      buttonProps={(group.path !== undefined) && {onClick: () => navigate(group.path)}}
      isActive={isActive}
      isExpanded={isActive}
    >
      {group.menus && group.menus.map((route, idx) => route.name && renderMenuItem(route, idx))}
    </NavExpandable>
  };

  const Navigation = () => (
    <Nav id="nav-primary-simple" theme={PageSectionVariants.dark}>
      <NavList id="nav-list-simple">
        {navigations.map(
          (route, idx) => route.label && (!route.children ? renderNavItem(route, idx) : renderNavGroup(route, idx))
        )}
        {menu.map(
          (route: IMenu, idx) => route.name && (!route.menus ? renderMenuItem(route, idx) : renderMenuGroup(route, idx))
        )}
      </NavList>
    </Nav>
  );

  return (
    <PageSidebar
      theme={PageSectionVariants.dark}
      isSidebarOpen={isNavOpen}
      >
        <PageSidebarBody>
          <Navigation />
        </PageSidebarBody>
    </PageSidebar>
  );
};

export { DashboardSidebar };