import React, {useEffect} from 'react';
import {Link, useMatches} from 'react-router-dom';
import { Page, SkipToContent, Breadcrumb, BreadcrumbItem, PageGroupProps, Banner, Flex, FlexItem, PageBreadcrumb } from '@patternfly/react-core';
import ExclamationTriangleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-triangle-icon';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DashboardHeader } from './DashboardHeader';
import { DashboardSidebar } from './DashboardSidebar';
import { Notification } from "./Notification"
import { INotify } from '../../store/models/INotify';
import { useQuery } from '@tanstack/react-query';
import { ListRes, getMenues, getNotifies } from '../../store/api';
import { Alerts } from './Alerts';
import { selectToken } from '../../store/slices/auth';
import { useAuth } from '../../service/auth';
import { IMenu } from '../../store/models/IMenu';



interface IDashboardLayout {
  children: React.ReactNode
  pageHeader?: React.ReactNode
  hasBreadcrumb?: boolean
  isSticky?: boolean
}

interface ICrumb {
  label: string
  link: string | undefined
}

const DashboardLayout: React.FunctionComponent<IDashboardLayout> = ({children, pageHeader, hasBreadcrumb=true, isSticky=false}) => {
  const pageId = 'main-content';
  const groupProps: PageGroupProps = isSticky ? {stickyOnBreakpoint: {default: 'top'}} : {}
  const matches = useMatches();
  const [crumbs, setCrumbs] = React.useState<ICrumb[]>([]);
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const [isNavOpenMobile, setIsNavOpenMobile] = React.useState(false); 
  const [isMobileView, setIsMobileView] = React.useState(true);
  const [isDrawerExpanded, setIsDrawerExpanded] = React.useState(false);
  const {t} = useTranslation();
  const token = useSelector(selectToken)
  
  const onNavToggleMobile = () => {
    setIsNavOpenMobile(!isNavOpenMobile);
  };

  const onNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const onPageResize = (event: MouseEvent | TouchEvent | React.KeyboardEvent, mobileObj: { mobileView: boolean; windowSize: number }) => {
    setIsMobileView(mobileObj.mobileView);
  };

  useEffect(() => {
    const c = matches
      //@ts-ignore
      .filter((match) => Boolean(match.handle?.crumb) && hasBreadcrumb)
      //@ts-ignore
      .map((match) => ({label: match.handle.crumb(), link: match.pathname}));
    setCrumbs(c);
  }, [matches])

  const {data: menu} = useQuery<IMenu[]>({
    queryKey: ['menu'],
    queryFn: async () =>  await getMenues(token!),
    initialData: []
  });

  const {data: notifies} = useQuery<INotify[]>({ 
    queryKey: ['notifies'], 
    queryFn: async () =>  await getNotifies(token!),
    refetchInterval: 60 * 1000,
    initialData: [],
    enabled: !!token
  });

  const dashboardBreadcrumb = (
    <PageBreadcrumb>
      <Breadcrumb className="main-breadcrumb">
        {crumbs.map((c, cIndex) => <BreadcrumbItem render={(p) => <Link className={p.className} to={cIndex === crumbs.length -1 ? "" : c.link!}>{c.label}</Link>} key={`crumb-${cIndex}`} to={c.link} isActive={cIndex === crumbs.length -1} />)}
      </Breadcrumb>
    </PageBreadcrumb>
  );
  
  const PageSkipToContent = <SkipToContent href={`#${pageId}`}>Skip to content</SkipToContent>;

  return (
    <Page
      mainContainerId={pageId}
      header={<DashboardHeader notifiesQuantity={notifies.length} isDrawerExpanded={isDrawerExpanded} setIsDrawerExpanded={setIsDrawerExpanded} isMobileView={isMobileView} onNavToggle={isMobileView ? onNavToggleMobile : onNavToggle} isNavOpen={isMobileView ? isNavOpenMobile : isNavOpen}/>}
      sidebar={<DashboardSidebar isNavOpen={isMobileView ? isNavOpenMobile : isNavOpen} menu={menu} />}
      skipToContent={PageSkipToContent}
      onPageResize={onPageResize}
      groupProps={groupProps}
      //breadcrumb={crumbs.length > 0 ? dashboardBreadcrumb : undefined}
      //isBreadcrumbWidthLimited
      //isBreadcrumbGrouped
      isNotificationDrawerExpanded={isDrawerExpanded}
      notificationDrawer={<Notification notifies={notifies} setIsDrawerExpanded={setIsDrawerExpanded} isDrawerExpanded={isDrawerExpanded}/>}
      additionalGroupedContent={
        <>
          <Alerts />
          {hasBreadcrumb && crumbs.length > 0 ? dashboardBreadcrumb : undefined}
          {pageHeader}
        </>
      }
      >
      {children}
    </Page>
  );
};

export { DashboardLayout };