import { AnyAction, ThunkAction, configureStore } from '@reduxjs/toolkit'

import authReducer from './slices/auth'
import alertReducer from './slices/alert'
import { useDispatch } from 'react-redux'

export const store = configureStore({
  reducer: {
    alert: alertReducer,
    auth: authReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk<T> = ThunkAction<T, RootState, null, AnyAction>