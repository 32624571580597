import { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Alert,
  AlertGroup,
  AlertActionCloseButton,
  AlertVariant
} from '@patternfly/react-core';
import { selectAlert, removeAlert } from '../../store/slices/alert';
import { useAppDispatch } from '../../store/store';

const FloatingAlertGroup: FunctionComponent = () => {
    const maxDisplayed = 4;
    const dispatch = useAppDispatch();
    const alerts = useSelector(selectAlert);
    const [overflowMessage, setOverflowMessage] = useState<string>('');

    const getOverflowMessage = (alertsNumber: number) => {
        const overflow = alertsNumber - maxDisplayed;
        if (overflow > 0) {
            return `View ${overflow} more alerts`;
        }
        return '';
    };

    /*
    useEffect(() => {
            addDangerAlert("Test co un titolo abbastanza lungoooooooo", 'Overflow message clicked')(dispatch)
    }, [])
    */

    useEffect(() => {
        setOverflowMessage(getOverflowMessage(alerts.length + 1));
    }, [alerts])

    const deleteAlert = (key: React.Key) => {
        dispatch(removeAlert(key))
    }

    const onOverflowClick = () => {
        // eslint-disable-next-line no-console
        console.log('Overflow message clicked');
    };

    return (
        <AlertGroup isToast isLiveRegion onOverflowClick={onOverflowClick} overflowMessage={overflowMessage}>
            {alerts.slice(0, maxDisplayed).map(({ key, variant, title, description, timeout }) => (
                <Alert
                    key={key}
                    variant={AlertVariant[variant || "info"]}
                    title={title}
                    timeout={timeout}
                    onTimeout={() => deleteAlert(key!)}
                    customIcon
                    actionClose={
                        <AlertActionCloseButton
                            title={title as string}
                            variantLabel={`${variant} alert`}
                            onClose={() => deleteAlert(key!)}
                        />
                    }
                >
                {description && <p>{description}</p>}
                </Alert>
            ))}
        </AlertGroup>
    );
};

export { FloatingAlertGroup }