import * as React from 'react';
import { PageSection, PageSectionVariants, TextContent, Text,
  Sidebar, SidebarContent, SidebarPanel } from '@patternfly/react-core';
import { DashboardLayout } from "../../components/Layout/DashboardLayout";
import { Categories } from '../../components/Categories';
import { Cards } from '../../components/Cards';
import { ICategory } from '../../store/models/ICategories';
import { ICard } from '../../store/models/ICards';
import { ToolbarItems } from '../../components/Toolbar';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { getCards, getCategories } from '../../store/api';
import { addDangerAlert } from '../../store/slices/alert';
import { useAppDispatch } from '../../store/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectToken } from '../../store/slices/auth';


const HomePage: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [selectedItem, setSelectedItem] = React.useState<ICategory[]>([]);
  const [input, setInput] = React.useState<string>('');
  const token = useSelector(selectToken)
  const {data: categories, error: categoryError} = useQuery<ICategory[], Error>({ 
    queryKey: ['categories'], 
    queryFn: async () =>  await getCategories(token!),
  });
  const {data, fetchNextPage, isFetching, hasNextPage, error: cardError} = useInfiniteQuery<ICard[]>({ 
    queryKey: ['cards', selectedItem, input], 
    queryFn: async (p) =>  await getCards(p, selectedItem, input, token!),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length === 0 ? undefined : allPages.length + 1;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      return allPages.length === 1 ? undefined : allPages.length - 1;
    },
    refetchOnWindowFocus: false
  });
  const cards = data?.pages.reduce((acc, i) => ([...acc, ...i]), [])

  return (
    <DashboardLayout
    hasBreadcrumb={false}
    isSticky={false}
    pageHeader={
      <PageSection variant={PageSectionVariants.light}>
            <TextContent>
              <Text component="h1">CNR-ISAC - Archive Data Center</Text>
            </TextContent>
      </PageSection>
      }
    >
      <PageSection variant={PageSectionVariants.default} padding={{ default: 'noPadding' }} >

      <Sidebar tabIndex={0} hasGutter>
        <SidebarPanel variant='sticky'>
          <Categories categories={categories!} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        </SidebarPanel>
    
        <SidebarContent hasNoBackground>
          <ToolbarItems setInput={setInput} input={input} />
          <Cards cards={cards!} fetchNext={fetchNextPage} hasNextPage={hasNextPage!} isFetching={isFetching} />
        </SidebarContent>
      </Sidebar>

      </PageSection>
    </DashboardLayout>
  )
}

export { HomePage };