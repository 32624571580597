import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AlertProps } from '@patternfly/react-core'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../store'

export interface NewAlertProps {
  title: string; 
  description?: string; 
  variant: AlertProps['variant']; 
  key: React.Key;
  timeout?: number;
}

export interface AlertState {
    value: {
        alerts: NewAlertProps[];
    }
}
  
// Define the initial state using that type
export const initialState: AlertState = {
    value: {
        alerts: [],
    },
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState: initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<NewAlertProps>) => {
      state.value.alerts = [action.payload, ...state.value.alerts]
    },
    removeAlert: (state, action: PayloadAction<React.Key>) => {
      state.value.alerts = state.value.alerts.filter(alert => alert.key !== action.payload)
    },
    clearAlerts: (state) => {
      state.value.alerts = []
    }
  },
})

const alertTimeout = 10000;
export const { addAlert, removeAlert, clearAlerts } = alertSlice.actions

export const addAlertAction = createAsyncThunk('alert/addAlertAction',
  async (alertParams: Partial<NewAlertProps>, {dispatch}) => {
    const key = uuidv4();
    dispatch(addAlert({...alertParams, key} as NewAlertProps));
  }
)

export const addSuccessAlert = (title: string, description?: string, timeout: number = alertTimeout) => {
  return addAlertAction({title, description, variant: 'success', timeout});
};

export const addDangerAlert = (title: string, description?: string, timeout: number = alertTimeout) => {
  return addAlertAction({title, description, variant: 'danger', timeout});
};

export const addErrorAlert = (title: string, error: unknown, timeout: number = alertTimeout) => {
  const description = error instanceof Error ? error.message : undefined;
  console.error(error);
  return addAlertAction({title, description, variant: 'danger', timeout});
};

export const addInfoAlert = (title: string, description?: string, timeout: number = alertTimeout) => {
  return addAlertAction({title, description, variant: 'info', timeout});
};

export const selectAlert = (state: RootState) => state.alert.value.alerts

export default alertSlice.reducer