import { FunctionComponent, useState } from 'react';
import {
    Brand,
    Button,
    Dropdown,
    DropdownGroup,
    DropdownItem,
    DropdownList,
    Masthead,
    MastheadBrand,
    MastheadContent,
    MastheadMain,
    MastheadToggle,
    MenuToggle,
    MenuToggleElement,
    NotificationBadge,
    NotificationBadgeVariant,
    PageToggleButton,
    Toolbar,
    ToolbarContent,
    ToolbarGroup,
    ToolbarItem, TooltipPosition, Truncate
} from '@patternfly/react-core';
import { BarsIcon, UserAltIcon } from '@patternfly/react-icons';
import logo from './../../new-logo-isac.svg';
import { useSelector } from 'react-redux';
import { performLogin, performLogout, selectUser } from '../../store/slices/auth';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/store';

interface IDashboardHeader {
    isNavOpen: boolean;
    isMobileView: boolean;
    onNavToggle: () => void;
    isDrawerExpanded: boolean;
    setIsDrawerExpanded: Function;
    notifiesQuantity: number;
}

const DashboardHeader: FunctionComponent<IDashboardHeader> = ({isMobileView, isNavOpen, onNavToggle, isDrawerExpanded, setIsDrawerExpanded, notifiesQuantity}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const user = useSelector(selectUser)
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
    const logoLink = 'https://www.isac.cnr.it/';

    const onUserDropdownToggle = () => {
        setIsUserDropdownOpen(!isUserDropdownOpen);
    };

    const onUserDropdownSelect = () => {
        setIsUserDropdownOpen(!isUserDropdownOpen);
    };

    const logout = () => {
      dispatch(performLogout())
      // navigate("/")
    }

    const login = () => {
      dispatch(performLogin())
    }

    const expandNotification = () => {
      setIsDrawerExpanded(!isDrawerExpanded)
    }

      const userDropdownItems = [
        <DropdownGroup key="group 2">
          <DropdownItem key="group 2 logout" onClick={logout}>Logout</DropdownItem>
        </DropdownGroup>
      ];
    
      const headerToolbar = (
        <Toolbar id="toolbar" isFullHeight isStatic>
          <ToolbarContent>
            <ToolbarGroup
              variant="icon-button-group"
              align={{ default: 'alignRight' }}
              spacer={{ default: 'spacerNone', md: 'spacerMd' }}
            >
              <ToolbarItem>
                {notifiesQuantity > 0 &&
                  <NotificationBadge
                    variant={NotificationBadgeVariant.read}
                    onClick={expandNotification}
                    aria-label="Notification badge with count and read variant"
                    isExpanded={isDrawerExpanded}
                    count={notifiesQuantity}
                  />}
              </ToolbarItem>
            </ToolbarGroup>
            <ToolbarItem visibility={{ default: 'visible'}}>
              {user?.email && <Dropdown
                isOpen={isUserDropdownOpen}
                onSelect={onUserDropdownSelect}
                onOpenChange={(isOpen: boolean) => setIsUserDropdownOpen(isOpen)}
                popperProps={{ position: 'right' }}
                toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
                  <MenuToggle
                    ref={toggleRef}
                    onClick={onUserDropdownToggle}
                    isFullHeight
                    isExpanded={isUserDropdownOpen}
                    icon={<UserAltIcon />}
                    style={{maxWidth: 220}}
                  >
                    {isMobileView ? "" : <Truncate content={user?.email || ''} tooltipPosition={TooltipPosition.leftStart} position={"middle"} trailingNumChars={6}/>}
                  </MenuToggle>
                )}
              >
                <DropdownList>{userDropdownItems}</DropdownList>
              </Dropdown>}
              {!user?.email && <Button onClick={login}>Login</Button>}
            </ToolbarItem>
          </ToolbarContent>
        </Toolbar>
      );
      
    return (
        <Masthead display={{ default: 'inline' }}>
         { <MastheadToggle>
              <PageToggleButton
                  variant="plain"
                  aria-label="Global navigation"
                  isSidebarOpen={isNavOpen}
                  onSidebarToggle={onNavToggle}
                  id="vertical-nav-toggle"
              >
                  <BarsIcon />
              </PageToggleButton>
          </MastheadToggle>}
          <MastheadMain>
              <MastheadBrand 
                style={{cursor: 'pointer'}}
                component={(props: any) => <a {...props} href={logoLink} target='_blank'/>}
              >
                  <Brand
                      heights={{ default: '36px' }}
                      src={logo}
                      alt="logo"
                  />
              </MastheadBrand>
          </MastheadMain>
          <MastheadContent>{headerToolbar}</MastheadContent>
        </Masthead>
    )
}

export { DashboardHeader };