import React from 'react';
import { Bullseye, Text, Spinner } from '@patternfly/react-core';

const SpinnerBullseye: React.FunctionComponent<{text: string}> = ({text}) => (
  <Bullseye>
    <Text component="h2">
      <Spinner isInline size="lg" aria-label="loading" />
      &nbsp;{text}
    </Text>
  </Bullseye>
)

export { SpinnerBullseye }