import { Banner, Button, Flex, FlexItem } from "@patternfly/react-core";
import { ExclamationTriangleIcon, InfoCircleIcon, ExclamationCircleIcon, CheckCircleIcon, BellIcon } from '@patternfly/react-icons';
import { IAlerts } from "../../store/models/IAlerts";
import { getAlerts } from "../../store/api";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { selectToken } from "../../store/slices/auth";
import { useAuth } from "../../service/auth";

export const Alerts = () => {

    const token = useSelector(selectToken)

    const {data: alerts} = useQuery<IAlerts[]>({ 
        queryKey: ['alerts'], 
        queryFn: async () =>  await getAlerts(token!),
        refetchInterval: 60 * 1000,
        initialData: [],
        enabled: !!token,
      });

    const openLink = (link: string) => {
        window.open(link, '_blank');
    }

    const chooseIcon = (type: string) => {
        let selectedIcon: any;
        switch(type) {
            case 'blue':
                selectedIcon = <InfoCircleIcon />;
                break;
            case 'red':
                selectedIcon = <ExclamationCircleIcon />;
                break;
            case 'green':
                selectedIcon = <CheckCircleIcon />;
                break;
            case 'gold':
                selectedIcon = <ExclamationTriangleIcon />;
                break;
            default:
                selectedIcon = <BellIcon />;
            }
        return selectedIcon;
    }


    return (
        <>
            {
                alerts &&
                alerts.map(alert => 
                    <Banner 
                        key={`alert-${alert.id}`} 
                        screenReaderText={alert.title} 
                        variant={alert.type}
                         >
                        <Flex 
                        spaceItems={{ default: 'spaceItemsSm' }}
                        justifyContent={{ default: 'justifyContentCenter' }}
                        >
                        <FlexItem>
                            {chooseIcon(alert.type)}
                        </FlexItem>
                        <FlexItem>{alert.title}</FlexItem>
                        <FlexItem><Button variant="link" isInline onClick={()=> openLink(alert.link)}>{alert.label}</Button></FlexItem>
                        </Flex>
                    </Banner>
                )
            }
        </>
    )
}