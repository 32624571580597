import Cookies from "universal-cookie";

export const TOKEN_COOKIE_NAME = "ADC_TOKEN";
export const USER_COOKIE_NAME = "ADC_USR";
export const cookies = new Cookies();

export const setCookieNeverExpired = (name: string, token: string) => {
    const expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 1);
    cookies.set(name, token, {expires: expireDate});
}

export const refreshAccessToken = async (refreshToken: string): Promise<any> => {
    try {
        const details = {
            client_id: process.env.REACT_APP_KEYCLOAK_CLIENT,
            grant_type: 'refresh_token',
            refresh_token: refreshToken
        };
        const formBody: string[] = [];
        Object.entries(details).forEach(([key, value]: [string, any]) => {
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(value);
            formBody.push(encodedKey + '=' + encodedValue);
        });
        const formData = formBody.join('&');
        const url = process.env.REACT_APP_KEYCLOAK_HOST + "/realms/" + process.env.REACT_APP_KEYCLOAK_REALM + "/protocol/openid-connect/token";
        const fetchOpt = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: formData
        }
        let response = await fetch(url, fetchOpt);
        const refreshedTokens = await response.json();
        if (!response.ok) throw refreshedTokens;
        return {
            accessToken: refreshedTokens.access_token,
            accessTokenExpired: Date.now() + refreshedTokens.expires_in * 1000,
            refreshTokenExpired: Date.now() + refreshedTokens.refresh_expires_in * 1000,
            refreshToken: refreshedTokens.refresh_token ?? refreshToken
        };
    } catch (error) {
        console.log('Token expired');
        console.error(error);
    }
}
