import { ICard } from "./models/ICards";
import { ICategory } from "./models/ICategories";
import { IMenu } from "./models/IMenu";
import { IPage } from "./models/IPage";

export interface ListRes<T> {
    data: T[]
    metadata?: {
        [key: string]: any
    }
}

interface Res<T> {
    data: T
    metadata?: {
        [key: string]: any
    }
}

const ITEM_PER_PAGE = 20;
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const API_URL = SERVER_URL + '/api';

const addCheckProps = (categories: ListRes<ICategory>) => {
    return categories.data.map(c => {
        //@ts-ignore
        const newC = {id: c.id, ...c.attributes, checkProps: {checked: false}}
        if(newC.children && newC.children.data && newC.children.data.length > 0) {
            newC.children = addCheckProps(newC.children)!
        } else {
            newC.children = undefined;
        }
        return newC;
    })
}

const clearMenu = (menus: ListRes<IMenu>) => {
    return menus.data.map(c => {
        let path = undefined
        //@ts-ignore
        if(c?.attributes?.link && c.attributes.link.length > 0){
        //@ts-ignore
            const link = c.attributes.link[0]
            if (link.__component === 'menu.link') {
                path = link.link
            } 
            if (link.__component === 'menu.page') {
                path = `/page/${link?.page?.data?.id}`
            }
        }
        //@ts-ignore
        const newC = {id: c.id, ...c.attributes, path}
        if(newC.menus && newC.menus.data && newC.menus.data.length > 0) {
            newC.menus = clearMenu(newC.menus)!
        } else {
            newC.menus = undefined;
        }
        return newC;
    })
}

const clearOneObj = function<T>(list: Res<T>) {
    //@ts-ignore
    return {...list.data.attributes, id:list.data.id}
}

const clearObj = function<T>(list: ListRes<T>) {
    //@ts-ignore
    return list && list.data ? list.data.map(c => ({id: c.id, ...c.attributes})) : []
}

const clearCards = function(list: ListRes<ICard>) {
    return clearObj<ICard>(list).map(c => ({...c, category: {id: c.category.data.id, ...c.category.data.attributes}, cover: c.cover.data}))
}

export const getCategories = async (token: string) => {
    const res = await fetch(API_URL + '/categories?populate=children&filters[parent][id][$null]=true', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...(token && {"Authorization": "Bearer " + token}),
        },
    });
    if(!res.ok){
        throw new Error(res.status + " " + res.statusText)
    }
    const cat = await res.json() as ListRes<ICategory>;
    return addCheckProps(cat || []);
}


export const getMenues = async (token: string) => {
    const res = await fetch(API_URL + '/menus?' +
    'populate[menus][populate][0]=link,menus' +
    '&populate[menus][populate][1]=link,menus' +
    '&populate[link][populate][page][fields]=title' +
    '&populate[menus][populate][link][populate][page][fields]=title' +
    '&filters[menu][id][$null]=true', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...(token && {"Authorization": "Bearer " + token}),
        },
    });
    
    if(!res.ok){
        throw new Error(res.status + " " + res.statusText)
    }
    const cat = await res.json() as ListRes<IMenu>;
    return clearMenu(cat);
}


export const getCards = async ({ pageParam = 1 }, selectedItem: ICategory[] = [], input?: string, token?: string) => {
    let queryParams = 'populate=category,cover&pagination[page]='+(pageParam)+'&pagination[pageSize]='+ITEM_PER_PAGE;
    if(input){
        queryParams = queryParams + '&filters[$or][0][name][$containsi]='+input;
        queryParams = queryParams + '&filters[$or][1][description][$containsi]='+input
    }
    if(selectedItem && selectedItem.length > 0){
        const catFilters = selectedItem.map((category, catIdx) => `filters[category][id][$in][${catIdx}]=${category.id}`)
        queryParams = queryParams + "&" + catFilters.join("&");
    }
    const res = await fetch(API_URL + '/cards?'+ queryParams, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            ...(token && {"Authorization": "Bearer " + token}),
        },
    })
    if(!res.ok){
        throw new Error(res.status + " " + res.statusText)
    }
    const cards = await res.json() as ListRes<ICard>;
    return clearCards(cards);
    }
    

export const getNotifies = async (token: string) => {
    const queryParams = `filters[$or][0][scadenza][$gte]=${new Date().toISOString()}&filters[$or][1][scadenza][$null]=true`;
    const res = await fetch(API_URL + '/notifies?' + queryParams, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        },
    });
    if(!res.ok){
        throw new Error(res.status + " " + res.statusText)
    }
    const notifies = await res.json();
    return clearObj(notifies || []);
}

export const getAlerts = async (token: string) => {
    const res = await fetch(API_URL + '/alerts', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
    });
    if(!res.ok){
        throw new Error(res.status + " " + res.statusText)
    }
    const alerts = await res.json();
    return clearObj(alerts || []);
}

export const getPage = async (id: string, token: string) => {
    const res = await fetch(API_URL + '/pages/' + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...(token && {"Authorization": "Bearer " + token}),
        },
    });
    
    if(!res.ok){
        throw new Error(res.status + " " + res.statusText)
    }
    const cat = await res.json() as Res<IPage>;
    return clearOneObj(cat);
}