import { QueryClientProvider, QueryClient, QueryCache } from '@tanstack/react-query'
import { FloatingAlertGroup } from './components/Alert';
import { AuthProvider } from './provider/AuthProvider';
import Routes from './Routes';
import { useAppDispatch } from './store/store';
import { addErrorAlert } from './store/slices/alert';

//const queryClient = new QueryClient()

function App() {
  const dispatch = useAppDispatch();
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        dispatch(addErrorAlert(query.queryKey[0] as string, error))
      },
    }),
  })

  return (
    <QueryClientProvider client={queryClient}>
        <AuthProvider>
                <Routes />
            <FloatingAlertGroup />
        </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
