import {debounce} from "lodash";
import { useMemo, useEffect, useRef } from "react";


export const useDebounce = (callback: Function, wait = 370) => {
  const ref = useRef<Function>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, wait);
  }, []);

  return debouncedCallback;
};