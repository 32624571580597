import { FunctionComponent, ReactNode } from 'react';
import { useInitAuth } from '../service/auth';
import { SpinnerBullseye } from '../components/Loaders/SpinnerBullseye';

interface IAuthProvider {
  children: ReactNode;
}

const AuthProvider: FunctionComponent<IAuthProvider> = ({children}) => {
  const {auth, keycloak} = useInitAuth();

  //@ts-ignore
  if(auth.loadingToken || auth.loadingUser || auth.loadingInit) {
    return <SpinnerBullseye text={"check auth..."} />
  }

  return children;
};

export { AuthProvider };