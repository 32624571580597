import React from 'react';
import {
  Button,
  NotificationDrawer,
  NotificationDrawerBody,
  NotificationDrawerHeader,
  NotificationDrawerGroupList,
  NotificationDrawerListItem,
  NotificationDrawerListItemBody,
  NotificationDrawerListItemHeader,
} from '@patternfly/react-core';
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';
import { INotify } from "../../store/models/INotify";

interface INotification {
    notifies: INotify[];
    setIsDrawerExpanded: Function;
    isDrawerExpanded: boolean;
}

export const Notification: React.FunctionComponent<INotification> = ({notifies, setIsDrawerExpanded, isDrawerExpanded}) => {
  const [firstGroupExpanded, setFirstGroupExpanded] = React.useState(true);

  const expandNotification = () => {
    setIsDrawerExpanded(!isDrawerExpanded);
  }

  const openLink = (link: string) => {
    window.open(link, '_blank');
  } 

  return (
    <NotificationDrawer>
      <NotificationDrawerHeader onClose={expandNotification} title='Notifiche' />
      <NotificationDrawerBody>
        <NotificationDrawerGroupList>
            

            {
                notifies &&
                notifies.map(notify => {
                    return (
                        <NotificationDrawerListItem 
                        variant={notify.type} 
                        key={`notify-${notify.id}`} >
                            <NotificationDrawerListItemHeader
                                variant={notify.type}
                                title={notify.title}
                                srTitle={notify.label}
                            >
                                {
                                    (notify.label && notify.link) &&
                                    <Button variant="link" onClick={()=> openLink(notify.link)} >{notify.label}</Button>
                                }
                            </NotificationDrawerListItemHeader>
                            <NotificationDrawerListItemBody>
                            {notify.description}
                            </NotificationDrawerListItemBody>
                        </NotificationDrawerListItem>
                        )
                    }
                )
            }
          

          
        </NotificationDrawerGroupList>
      </NotificationDrawerBody>
    </NotificationDrawer>
  );
};