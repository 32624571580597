import { FunctionComponent, useState, useEffect } from "react";
import { ICard } from "../../store/models/ICards";
import { Card, CardTitle, CardBody, Gallery, CardHeader, Brand } from '@patternfly/react-core';
import { useInView } from 'react-intersection-observer';
import { SERVER_URL } from "../../store/api";

interface ICards {
    cards: ICard[];
    fetchNext: Function
    isFetching: boolean;
    hasNextPage: boolean;
}

const Cards: FunctionComponent<ICards> = ({cards=[], fetchNext, isFetching, hasNextPage}) => {
    const [viewCards, setViewCards] = useState(cards);
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
      });

      useEffect(() => {
        setViewCards(cards);
    }, [cards]);

    useEffect(() => {
        if(inView && !isFetching && hasNextPage) {
            fetchNext();
        }
    }, [inView]);

    return (
        <>
        <Gallery hasGutter minWidths={{default: '100%', md: '50%', lg: '33%', xl:'22%'}}>
            {
                viewCards.map(card => {
                    return (


                        <Card
                        isRounded
                        isFullHeight
                        isClickable
                        key={`card-${card.name}`} 
                        id={card.name}
                        onClick={() => window.open(card.link, '_blank')}
                        >
                        <CardHeader 
                        // className="card-fix"
                            selectableActions={{
                                // eslint-disable-next-line no-console
                                selectableActionId: card.id,
                              }}
                        >
                        <CardTitle>{card.category.name}</CardTitle>
                        </CardHeader>
                        <Brand src={SERVER_URL + card.cover.attributes.formats.small.url} alt="PatternFly logo"
                        widths={{default: '100%'}}
                        heights={{default: '300px'}}
                        style={{objectFit: 'cover'}}
                        />
                        <CardTitle style={{paddingTop: 20}}>{card.name.charAt(0).toUpperCase() + card.name.slice(1)}</CardTitle>
                        <CardBody>{card.description}</CardBody>
                        </Card>
                            );
                        })
            }
        </Gallery>
        {viewCards && viewCards.length > 0 && <div ref={ref} 
        style={{height: 15}}
        ></div>}
        </>
    );
};

export {Cards};