import { Dispatch, PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'universal-cookie'
import { RootState } from '../store'
import { IUser } from '../models/IUser'
import { AUTH_COOKIE_NAME } from '../../utils/const';
import { kc } from '../../service/auth';

export interface AuthState {
  user: IUser | undefined;
  loadingInit: boolean;
  loadingUser: boolean;
  token: string | undefined;
  loadingToken: boolean;
}
  
// Define the initial state using that type
export const initialState: AuthState = {
    user: undefined,
    loadingInit: false,
    loadingUser: false,
    token: undefined,
    loadingToken: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    value: initialState
  },
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.value.user = action.payload
    },
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.value.loadingInit = action.payload
    },
    setToken: (state, action: PayloadAction<{token: string}>) => {
      state.value.token = action.payload.token
    },
    setAuth: (state, action: PayloadAction<AuthState>) => {
      state.value.user = action.payload.user
      state.value.loadingUser = action.payload.loadingUser
      state.value.token = action.payload.token
      state.value.loadingToken = action.payload.loadingToken
    },
    setLoadingUser: (state, action: PayloadAction<boolean>) => {
      state.value.loadingUser = action.payload
    },
    setLoadingToken: (state, action: PayloadAction<boolean>) => {
      state.value.loadingToken = action.payload
    },
    resetAuth: (state) => {
      state.value = initialState
    }
  },
})

export const { setUser, setAuth, resetAuth, setToken, setLoadingUser, setLoadingToken , setInitialized } = authSlice.actions

export const cookies = new Cookies();

export const getUserByToken = (token: string) => (dispatch: Dispatch): Promise<IUser>  => {
  dispatch(setLoadingUser(true))
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      //TODO: get right user data
      const newUser = {id: "id", email: "solouansanainconsapevolelibidine@serviszioemaillungo.it"}
      dispatch(setLoadingUser(false))
      dispatch(setUser(newUser))
      resolve(newUser)
    }, 2000)
  });
}

export const performLogout = createAsyncThunk('auth/performLogout',
  async (url: string | undefined, {dispatch}) => {
    // await tileCacheDb.destroy();
    // await raLocalDb.destroy();
    await dispatch(setAuth(initialState))
    if(kc.authenticated) {
      kc.logout({redirectUri: window.location.origin + (url || "/")});
    } else {
      console.info("alredy log out")
    }
  }
)

export const performLogin = createAsyncThunk('auth/performLogin',
  async (url: string | undefined, {dispatch}) => {
    kc.login();
  }
)

export const selectAuth = (state: RootState) => state.auth.value
export const selectUser = (state: RootState) => state.auth.value.user
export const selectToken = (state: RootState) => state.auth.value.token

export default authSlice.reducer