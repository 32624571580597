import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
import { NotFound } from "./pages/NotFound";
import { HomePage } from "./pages/HomePage";
import { Page } from "./pages/Page";
import { RequireAuth } from "./components/Auth/PrivateRoute";
import { ForcePublicRoute } from "./components/Auth/ForcePublicRoute";

export interface NavigationItem {
    path: string;
    label: string;
    children: NavigationItem[] | null;
}

export const navigations = [{
    path: "/",
    label: "Home",
    children: null
}]

const routes = [
    {
        path: "/",
        handle: {
            crumb: () => "Home"
        },
        // Component: RequireAuth,
        children: [{
            index: true,
            Component: HomePage,
        },
        {
            path: '/page/:id',
            Component: Page,
        }],
    },
    {
        path: "*",
        Component: NotFound,
    },
]
const routerBrowser = createBrowserRouter(routes);

export default function Routes() {
    return <RouterProvider router={routerBrowser} fallbackElement={<p>Loading...</p>} />;
}
  