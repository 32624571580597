import React, { FormEvent, ReactNode, useEffect, useState } from 'react';
import { Toolbar, ToolbarItem, ToolbarContent, Flex, FlexItem } from '@patternfly/react-core';
import { SearchInput } from '@patternfly/react-core';
import { useDebounce } from '../../utils/useDebounce';


interface IToolbar {
    setInput: (item: string) => any;
    input: string;
}

export const ToolbarItems: React.FunctionComponent<IToolbar> = ({setInput, input}) => {
  const [thisInput, setThisInput] = useState(input);
  const debounceRun = useDebounce(
    () => setInput(thisInput)
  );
  const onChange = (event: FormEvent<HTMLInputElement>, value: string) => {
    setThisInput(value);
    debounceRun();
  }
  const items = (
    <React.Fragment>
      <ToolbarItem variant="search-filter">
        <SearchInput
        aria-label="Items example search input"
        onChange={onChange}
        value={input}
        onClear={() => {
          setInput('');
          }}
        />
      </ToolbarItem>
    </React.Fragment>
  );

  return (
        <Toolbar id="toolbar-items-example" style={{margin: '0 0 15px'}}>
          <ToolbarContent>{items}</ToolbarContent>
        </Toolbar>
  );
}
